
import PromoPT15USDHeader from "@/components/landingPages/quickPurchase/promotions/proteam15usd/PromoPT15USDHeader.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "PromoPT15USD",
  components: { PromoPT15USDHeader },
})
export default class PromoPT15USD extends Vue {
  bgImagePath = require("@/assets/images/bottom_bg_liquid.png");

  get promotionPageStyles() {
    return {
      backgroundImage: `url("${this.bgImagePath}")`,
      backgroundPosition: "bottom center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
    };
  }

  get transitionName() {
    return "fade";
  }
}
